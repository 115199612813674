import { Store } from "react-stores";
import { IDashboardStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";
import { calculateMiddleDate, getMarketsToformattedString } from "../helpers/dataHelper";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const DashboardStore = new Store<IDashboardStore>({
  chargersStatus: null,
  revenue: [],
  energyConsumption: [],
  timeUtilization: [],
  capacityUtilization: [],
});

export const handleGetChargerStatus = async (companyMarketIds: number[]) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    let marketsString = getMarketsToformattedString(companyMarketIds);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Dashboards/GetDashboardEVSEStatus${marketsString}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    DashboardStore.setState({
      ...DashboardStore.state,
      chargersStatus: data,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetRevenue = async (
  startDate: string = "",
  endDate: string = "",
  companyMarketIds: number[],
  currencyISO: string
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    let marketsString = getMarketsToformattedString(companyMarketIds);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Dashboards/GetDashboardRevenue${marketsString}${startDate ? "&from=" + startDate : ""
      }${endDate ? "&to=" + endDate : ""}&currencyISO=${currencyISO}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    DashboardStore.setState({
      ...DashboardStore.state,
      revenue: data.pointDtos.map((point) => {
        return { value: point.price, from: point.from, to: point.to, middle: calculateMiddleDate(point.from, point.to) };
      }),
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetEnergyConsumption = async (
  startDate: string = "",
  endDate: string = "",
  companyMarketIds: number[]
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    let marketsString = getMarketsToformattedString(companyMarketIds);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Dashboards/GetDashboardEnergyConsumption${marketsString}${startDate ? "&from=" + startDate : ""
      }${endDate ? "&to=" + endDate : ""}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    DashboardStore.setState({
      ...DashboardStore.state,
      energyConsumption: data.pointDtos.map((point) => {
        return { value: point.consumption, from: point.from, to: point.to, middle: calculateMiddleDate(point.from, point.to) };
      }),
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetUtilization = async (
  startDate: string = "",
  endDate: string = "",
  companyMarketIds: number[]
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    let marketsString = getMarketsToformattedString(companyMarketIds);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Dashboards/GetDashboardUtilization${marketsString}${startDate ? "&from=" + startDate : ""
      }${endDate ? "&to=" + endDate : ""}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    DashboardStore.setState({
      ...DashboardStore.state,
      timeUtilization: data.timeUtilizationPointDtos.map((point) => {
        return { value: point.evseOccupancy, from: point.from, to: point.to, middle: calculateMiddleDate(point.from, point.to) };
      }),
      capacityUtilization: data.capacityUtilizationPointDtos.map((point) => {
        return { value: point.capacityUtilization, from: point.from, to: point.to, middle: calculateMiddleDate(point.from, point.to) };
      }),
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

